<template>
  <v-row no-gutters class>
    <v-col cols="12" sm="8" lg="8">
      <!-- <v-row
        v-if="hasLicenceHC && isTimeToRenewLHC"
        class="d-flex justify-content-center mt-3 pb-0 mb-0"
      > -->
<!--      <v-row-->
<!--        v-if="hasLicenceHC"-->
<!--        class="d-flex justify-content-center mt-3 pb-0 mb-0"-->
<!--      >-->
<!--        <router-link-->
<!--          :to="{-->
<!--            name: 'hors_club__inscription',-->
<!--            params: { user_id: currentUserId },-->
<!--          }"-->
<!--          ><v-btn>-->
<!--            JE RENOUVELLE MA LICENCE HORS CLUB POUR LA SAISON-->
<!--            {{ parseInt(currentSaison.id) + 1 }} ici-->
<!--          </v-btn>-->
<!--        </router-link>-->
<!--      </v-row>-->
<!--      <v-row v-if="!hasLicenceHC" class="d-flex justify-content-center mt-3 pb-0 mb-0">-->
<!--        <router-link-->
<!--          class="link__dematerialise"-->
<!--          :to="{-->
<!--            name: 'licence_dematerilise_inscription',-->
<!--            params: {-->
<!--              structure_id: ID_Club,-->
<!--              user_id: currentUserId,-->
<!--            },-->
<!--          }"-->
<!--        >-->
<!--          <v-btn-->
<!--            v-if="-->
<!--              (ID_Club && EST_AdhesionOnlineOuverte && !hasLicenceSaison) ||-->
<!--              (isAdministrator && haveGroupe && !hasLicenceSaison)-->
<!--            "-->
<!--            rounded-->
<!--            color="#01abe8"-->
<!--            light-->
<!--            style="color: white; font-family: 'Roboto'; font-size: 15px;"-->
<!--            link-->
<!--          >-->
<!--            RENOUVELER MON ADHESION-->
<!--          </v-btn>-->
<!--        </router-link>-->
<!--      </v-row>-->

      <v-row class="flex-column dashboard-left px-3 pt-0 mt-0">
        <v-col
          class="p-0 h-100"
          style="min-height: 100vh; padding: 0 25px; width: 70vw;"
        >
          <div class="pb-3" style="padding: 1.5rem 6rem !important;">
            <CardActualites
              :newsStructureIds="parentStructureIDs"
              :clubStructureIds="[currentStructureId]"
              :showName="true"
              title="Actualités"
              :selectedDate="date"
              :hasSelectedDate="hasSelectedDate"
              :structureIDS="structureIDS"
            ></CardActualites>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      v-if="!loading"
      cols="12"
      sm="4"
      lg="4"
      class="p-3 __bkgr-blue-light accueil__sidebar"
    >
      <v-row>
        <v-col>
          <Avatar
            size="112"
            upload
            :context="{ ID_Utilisateur: currentUserId }"
          ></Avatar>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <div class="avatar__info text-center __font-small">
            <BasicInfoUser
              :context="{
                ID_Utilisateur: currentUserId,
                ID_Saison: currentSaison.id,
              }"
              :showProfilModification="true"
            ></BasicInfoUser>

            <!-- <div class="mt-4">
              <div class="avatar__config-link">
                <font-awesome-icon icon="cog" />
                <router-link
                  :to="{ name: 'mon_compte__modification_cordonnees' }"
                  >Modifier mes informations</router-link
                >
              </div>
            </div> -->
          </div>
        </v-col>
      </v-row>

      <v-row class="accueil__news-calendar">
        <v-col cols="12" class="my-0 py-0 px-0">
          <v-date-picker
            class="datePicker__licencie"
            ref="picker"
            v-model="date"
            full-width
            :events="Events"
            :value="Events"
          ></v-date-picker>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col cols="12" class="my-0 py-0 py-0 px-0 container_news">
          <div class="title __bkgr-blue-bright text-center py-2">
            <font-awesome-icon icon="calendar-alt" class="mr-2" /><span
              style="text-transform:capitalize; font-family:'FF Clan, Verdana, Arial, sans-serif'"
            >
              Formations
            </span>
            <span style="text-transform:lowerCase;font-family:'FF Clan, Verdana, Arial, sans-serif'">
              et</span
            >
            <span style="text-transform:capitalize;font-family:'FF Clan, Verdana, Arial, sans-serif'">
              compétitions</span
            >
          </div>

          <div class="accueil__news-container ma-4">
            <v-card>
              <ul>
                <li
                  class="competition_formation"
                  v-for="(note, index) in allNotes"
                  :key="note"
                >
                  <span style="width:85%">{{ note }}</span>
                  <div class="date-circle">{{ index * 6 + 1 }}</div>
                </li>
              </ul>
            </v-card>
          </div>
        </v-col>
      </v-row> -->
    </v-col>
  </v-row>
</template>

<script>
import Avatar from "@/components/Common/Common__Avatar.vue";
import CardActualites from "@/components/Accueil/FFMENews/CardActualites.vue";
// import CardActualitesStructures from "@/components/Accueil/FFMENews/CardActualitesStructures.vue";
import BasicInfoUser from "@/components/User/User__BasicInfo.vue";
import { mapActions, mapGetters } from "vuex";
import { clear_notifications, warning_notification } from "../../plugins/notifications";
import LicenceRenewalNotification from "../../components/utils/licenceRenewalNotification.vue";

export default {
  components: {
    Avatar,
    CardActualites,
    BasicInfoUser,
    // CardActualitesStructures,
  },

  data: () => ({
    EST_AdhesionOnlineOuverte: false,
    date: new Date().toISOString().substr(0, 10),
    pickerDate: null,
    notes: [],
    allNotes: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit adipiscing. ",
      "Lorem ipsum sit amet, consectetur adipiscing dolor. ",
      "Lorem consectetur ipsum dolor sit amet, elit. ",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur. ",
      "Lorem ipsum dolor sit adipiscing amet, elit. ",
      "Lorem ipsum amet, consectetur adipiscing elit, dolor sit ",
    ],
    loading: true,
    hasSelectedDate: false,
    structureIDS: [],
    userLicenceType: 2,
    hasLicenceHC: false,
    ID_Club: null,
    hasLicenceSaison: false,
    lastLicenceProduct: null,
  }),

  async created() {
    await this.lastLicence();
    this.loading = false;
    await this.getStructuresIDS();
    await this.setData();

    if (this.lastLicenceProduct.slug.includes('hors_club') && this.lastLicenceSeason !== this.currentSaison.id) {
        clear_notifications();
        warning_notification(
          {
            component: LicenceRenewalNotification,
            listeners: {
              selectedSeason: () => this.currentSaison.id,
              renew: () => {
                this.renewLicence();
              },
            }
          },
          {
            position: 'top-center',
            timeout: false,
            closeButton: false,
            closeOnClick: false,
            draggable: true,
            draggablePercent: 0.6,
          }
        );

        return;
      }
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructureId",
      "currentStructure",
      "getGroupeCreneaux",
    ]),
    ...mapGetters("user", ["currentUser", "currentUserId", "getRole"]),
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),
    parentStructureIDs() {
      if (this.currentStructure && this.currentStructure.STR_Structure) {
        let {
          ID_Ligue,
          ID_Federation,
          ID_ComiteTerritorial,
        } = this.currentStructure.STR_Structure;
        let parentIds = [];
        if (ID_Ligue) parentIds.push(ID_Ligue);
        if (ID_Federation) parentIds.push(ID_Federation);
        if (ID_ComiteTerritorial) parentIds.push(ID_ComiteTerritorial);
        return parentIds;
      }
      return [];
    },
    isTimeToRenewLHC() {
      const today = new Date().toISOString();
      const dayToRenew = new Date(
        `${this.currentSaison.id}-09-01`
      ).toISOString();

      const renew = today > dayToRenew;
      return renew;
    },
    functionEvents() {
      return this.dateFunctionEvents;
    },
    Events() {
      let date1 = new Date("2021-02-15").toISOString().substr(0, 10);
      let date2 = new Date("2021-02-19").toISOString().substr(0, 10);
      return [date1, date2];
    },

    isAdministrator() {
      let role = this.getRole?.NomRole;
      if (
        role === "Administrateur" ||
        role === "Administrateur SI" ||
        role === "Administrateur AT"
      )
        return true;
      return false;
    },
    haveGroupe() {
      const groupName = this.getGroupeCreneaux?.filter(
        (groupe) => groupe.STR_GroupeSaisonCreneau[0].EST_VenteOuverteClub
      );
      if (groupName) return true;
      return false;
    },
  },

  methods: {
    dateFunctionEvents(date) {
      const [, , day] = date.split("-");
      if ([12, 17, 28, 1, 19, 22].includes(parseInt(day, 10))) return ["event"];
      // if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
      return false;
    },
    ...mapActions("structure", [
      "getStructuresByID",
      "getStructureByID",
      "setCurrentStructureFederal",
      "getGroupeCreneauFullData",
    ]),
    ...mapActions("utilisateurs", [
      "getUserLicences",
    ]),
    // ...mapActions("user", ["getUserLicenceType", "getUserLicence"]),

    ...mapActions("basics", ["getListTypeLicence"]),

    redirectToHC() {
      this.$router.push({
        name: "Licence_Hors_Club",
        // params: { id: this.item.id },
      });
    },
    async getStructuresIDS() {
      await this.setCurrentStructureFederal();
      let response = await this.getStructuresByID({
        ID_Utilisateur: this.currentUserId,
      });
      let STRUSERS = response && [...response];
      this.structureIDS = STRUSERS?.map((str) => str.ID_Structure);
      localStorage.ffme_structure_id = this.cu;
      // process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME.localStorage = this.currentStructureId;
    },
    async setData() {
      await this.setCurrentStructureFederal();
      // const fulldata = await this.getGroupeCreneauFullData({
      //   ID_Structure: parseInt(this.currentStructureId),
      //   ID_Saison: this.currentSaison.id,
      // });
      // const licences = await this.getUserLicence({
      //   ID_Utilisateur: this.currentUserId,
      // });
      //
      // const lastLicence = licences && licences[0];
      // this.ID_Club = lastLicence && lastLicence.ID_Structure;
      // this.EST_AdhesionOnlineOuverte =
      //   lastLicence?.STR_Structure?.EST_AdhesionOnlineOuverte;
      // console.log(
      //   `this.EST_AdhesionOnlineOuvere`,
      //   this.EST_AdhesionOnlineOuverte
      // );
      // const licence = await this.getUserLicenceType({
      //   ID_Utilisateur: this.currentUser?.id,
      //   ID_Saison: parseInt(this.currentSaison?.id),
      // });
      // const licenceType = await this.getListTypeLicence();
      // const licenceHorsClub = licenceType?.filter(
      //   (licence) =>
      //     licence.SlugTypeLicence === "hors_club" ||
      //     licence.SlugTypeLicence === "hors_club_jeune"
      // );
      // this.userLicenceType = licence.filter(
      //   (licence) =>
      //     licence.ID_TypeLicence === 4 || licence.ID_TypeLicence === 5
      // );
      // if (this.userLicenceType.length > 0) {
      //   this.hasLicenceHC = true;
      // }
      // this.hasLicenceSaison = licenceType.some(
      //   (licence) =>
      //     licence.SlugTypeLicence === "famille" ||
      //     licence.SlugTypeLicence === "jeune" ||
      //     licence.SlugTypeLicence === "adulte"
      // );
    },

    async lastLicence() {
      const response = await this.getUserLicences({
        user: this.currentUserId,
        active: true,
      })

      if (response.length === 0) {
        return null;
      }

      const lastLicence = response.sort((a, b) => b.season.id - a.season.id)[0];
      this.lastLicenceSeason = lastLicence.season.id;
      this.lastLicenceProduct = lastLicence.product;

      return lastLicence;
    },

    async renewLicence() {
      const lastLicence = await this.lastLicence();

      window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/renouvellement/${lastLicence.id}?structureId=${lastLicence.structure.id}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
  },

  watch: {
    pickerDate() {
      this.notes = [
        this.allNotes[Math.floor(Math.random() * 5)],
        this.allNotes[Math.floor(Math.random() * 5)],
        this.allNotes[Math.floor(Math.random() * 5)],
      ].filter((value, index, self) => self.indexOf(value) === index);
    },

    date() {
      this.notes = [
        this.allNotes[Math.floor(Math.random() * 5)],
        this.allNotes[Math.floor(Math.random() * 5)],
        this.allNotes[Math.floor(Math.random() * 5)],
      ].filter((value, index, self) => self.indexOf(value) === index);
      this.hasSelectedDate = true;
      console.log("Events", this.Events);
    },
  },
};
</script>

<style lang="scss">
$dc-radius: 42px;
.link__dematerialise:hover {
  text-decoration: none !important;
}
.date-circle {
  width: $dc-radius;
  height: $dc-radius;
  background: #01abe8;
  border-radius: $dc-radius;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-right: 20px;
  flex: 0 0 42px;
  font-weight: 800;
}

.accueil__news-container {
  height: calc(100vh - 742px);
  overflow-y: auto;
  direction: rtl;
  li {
    font-size: 0.9rem;
    font-weight: 300;
    position: relative;
    display: flex;
    align-items: center;
    min-height: #{$dc-radius + 10px};
    border-bottom: 1px solid rgba(150, 150, 150, 0.24);
    padding: 10px;
    &:last-child {
      border: none;
    }
  }
}
.datePicker__licencie .v-btn--active {
  background-color: #01abe8 !important;
}
.accueil__sidebar {
  box-shadow: 0 -3px 4px -2px #646c9e;
  height: calc(100vh - 80px);
  max-height: 100vh;
  overflow-y: hidden;
}
.competition_formation {
  display: flex;
}
.competition_formation div {
  margin-right: auto;
}

.accueil__news-calendar {
  .v-picker__title {
    display: none !important;
  }

  .v-picker--date {
    border-radius: 0;
    box-shadow: none;
  }

  .v-date-picker-table {
    height: 250px;
  }

  // EVENTS
  .v-date-picker-table__events {
    position: absolute;
    top: 2px;
    left: -1px;
  }
  .v-btn__content {
    z-index: 1;
    color: #c4c4c6;
  }
  .v-btn--active {
    .v-btn__content {
      color: white;
    }
  }
  .event {
    width: 27px;
    height: 27px;
    background: #646c9e;
  }
}
.accueil__content-top {
  flex: 0 0 50%;
  max-height: 50%;
  overflow: auto;
}

.accueil__list-wrapper {
  // half visible height - half header height - v-card title - vcard external padding - padding wrapper
  max-height: calc(100vh - 40px - 64px - 36px - 12px);
  // overflow: auto;
  direction: rtl;
  padding-left: 20px;
}
.accueil__news-list {
  direction: ltr;
}

.accueil__content-bottom {
  flex: 0 0 50%;
  max-height: 50%;
}
.accueil__news-card {
  border-radius: 12px !important;
}
.accueil__news-teaser {
  border-bottom: 1px solid #dedede;
  position: relative;

  &:last-child {
    border: none;
  }

  .-posted {
    display: flex;
    align-items: center;
    font-size: 0.7rem;
    font-style: italic;
    color: grey;
    padding-top: 3px;

    .svg-inline--fa {
      margin-right: 10px;
      font-size: 0.9rem;
      color: #2d4980;
    }
  }

  .closer {
    position: absolute;
    top: 50%;
    bottom: 0;
    right: 6px;
    margin-top: -10px;
    color: #2d4980;
    font-size: 1.3rem;
  }
}
</style>

<style lang="scss" scoped>
.title {
  color: white;
  // text-transform: capitalize;
}
.dashboard-left {
  height: calc(100vh - 80px);
}
</style>
